@import "~@russpass/ui/build/assets/styles/common/vars.sass"
@import "~@russpass/ui/build/assets/styles/common/buttons.sass"
@import "~@russpass/ui/build/assets/styles/common/grid.sass"
@import "~@russpass/ui/build/assets/styles/common/typography.sass"

.check-wrap
    display: flex
    flex-direction: column
    position: relative
    width: 100%
    min-height: 100%

    +mediamax(md)
        justify-content: flex-end

.qr-field
    display: flex
    justify-content: center
    padding-top: 128px
    position: relative
    z-index: 2

    +mediamax(md)
        padding-top: 0
        padding: 12px
        width: 100%

.qr-reader
    display: none
    position: fixed
    background: black
    top: 0
    bottom: 0
    left: 0
    right: 0
    z-index: 1

    +mediamax(md)
        display: block

    &__scanner
        height: 100%

        section
            height: 100%

            div
                top: 50%!important
                left: 50%!important
                transform: translate(-50%, -50%)
                z-index: 1
                box-sizing: border-box
                border: none!important
                box-shadow: none !important
                position: absolute
                width: 50% !important
                height: 50% !important

                background-image: url("../../../../assets/images/icons/scanner.svg")
                background-repeat: no-repeat
                background-size: 100.8% 102%
                border-radius: 8%
                background-position: -2px -1px

.qr-reader--hide-area
    .qr-reader__scanner
        section
            div
                display: none
