@import "~@russpass/ui/build/assets/styles/common/vars.sass"
@import "~@russpass/ui/build/assets/styles/common/buttons.sass"
@import "~@russpass/ui/build/assets/styles/common/grid.sass"
@import "~@russpass/ui/build/assets/styles/common/typography.sass"


.enter-qr-code
    width: 100%
    max-width: 450px
    &__form
        display: grid
        grid-template-areas: "ticket-number-field control" "group-ticket-field group-ticket-field" 
        align-items: center
        justify-content: space-between
        width: 100%
        background: $background-floating
        border-radius: $border-radius-md

    &__field
        width: 100%

        &--grid-template-1
            grid-area: ticket-number-field
        
        &--grid-template-2
            grid-area: group-ticket-field

    &__btn-wrap
        margin-right: 10px
        grid-area: control

    &__btn
        @extend %button
        @extend %button--second
