@import "~@russpass/ui/build/assets/styles/common/vars.sass"
@import "~@russpass/ui/build/assets/styles/common/buttons.sass"
@import "~@russpass/ui/build/assets/styles/common/grid.sass"
@import "~@russpass/ui/build/assets/styles/common/typography.sass"

.screen-error
    position: fixed
    top: 0
    bottom: 0
    right: 0
    left: 0
    max-width: 100%
    width: 100%
    background: $background-default
    display: flex
    flex-direction: column
    justify-content: center
    z-index: 3
    overflow: hidden

    &__stub
        display: flex
        flex-direction: column
        justify-content: flex-end
        flex: 1
        padding-bottom: 40px

    &__content
        display: flex
        align-items: center
        justify-content: space-between
        margin: 0 auto
        padding: 12px 16px
        flex-direction: column
        width: auto

    &__info
        @extend %body
        margin-top: 8px
        margin-bottom: 20px
        width: 100%

    &__info-type
        display: -webkit-box
        -webkit-box-orient: vertical
        word-break: break-word

    &__info-description
        opacity: .5
        white-space: nowrap
        text-overflow: ellipsis

    &__info-type,
    &__info-description
        width: 100%
        overflow: hidden
        text-align: center
        max-width: 280px
        white-space: normal
        margin: 4px 0

    &__btn-wrap
        @extend %body
        color: $color-secondary

    &__btn
        @extend %button
        @extend %button--second

    &__helper
        display: flex
        flex-direction: column
        justify-content: flex-end
        flex: 1
        padding-bottom: 40px
        text-align: center

    &__helper-description
        color: $color-text-secondary
        @extend %caption

    &__helper-phone
        @extend %body
        text-decoration: none
