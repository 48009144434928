@import "~@russpass/ui/build/assets/styles/common/vars.sass"
@import "~@russpass/ui/build/assets/styles/common/buttons.sass"
@import "~@russpass/ui/build/assets/styles/common/grid.sass"
@import "~@russpass/ui/build/assets/styles/common/typography.sass"


.history-chart__legend
    @extend %caption
    color: $color-text-secondary
    list-style: none
    margin-bottom: 25px
    margin-top: 24px
    padding: 0
    margin-left: 45px

    +mediamax(md)
        margin-top: 16px

    &-item
        display: flex
        align-items: center

    &-circle
        border-radius: 50%
        width: 8px
        height: 8px
        margin-right: 8px
