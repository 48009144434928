@import "~@russpass/ui/build/assets/styles/common/vars.sass"
@import "~@russpass/ui/build/assets/styles/common/buttons.sass"
@import "~@russpass/ui/build/assets/styles/common/grid.sass"
@import "~@russpass/ui/build/assets/styles/common/typography.sass"


@keyframes fade-in
    0%
        opacity: 0

    100%
        opacity: 1

.fade-in
    animation: fade-in 0.1s ease-out both

@keyframes fade-out
    0%
        opacity: 1

    100%
        opacity: 0

.fade-out
    animation: fade-out 0.1s ease-in both
