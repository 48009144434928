@import "~@russpass/ui/build/assets/styles/common/vars.sass"
@import "~@russpass/ui/build/assets/styles/common/buttons.sass"
@import "~@russpass/ui/build/assets/styles/common/grid.sass"
@import "~@russpass/ui/build/assets/styles/common/typography.sass"


.history-chart
    width: 100%
    height: 350px
    padding: 0 12px

    +mediamax(md)
        padding: 0

    .recharts-cartesian-axis-line
        display: none
