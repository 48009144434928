@import "~@russpass/ui/build/assets/styles/common/vars.sass"
@import "~@russpass/ui/build/assets/styles/common/buttons.sass"
@import "~@russpass/ui/build/assets/styles/common/grid.sass"
@import "~@russpass/ui/build/assets/styles/common/typography.sass"


.selected-range
    position: absolute
    background-color: $background-floating
    border-radius: $border-radius-md
    box-shadow: 0px 12px 16px rgba(0, 0, 0, 0.04)
    z-index: 2
    top: 70px
    left: 50%
    transform: translateX(-50%)

    +mediamax(md)
        position: fixed
        left: 0
        transform: translateX(0)
        top: 0
        right: 0
        background-color: $background-default
        height: 100%
        overflow-y: auto
        min-height: calc(var(--100vvh))
        min-height: -webkit-fill-available

    &__header
        display: none
        align-items: center
        background-color: $background-default
        position: sticky
        z-index: 2
        top: 0
        padding: 12px
        box-shadow: 0px 0.5px 0px #F1E5D4
        border-bottom: 2px solid rgba(245, 223, 184, 0.5)

        +mediamax(md)
            display: flex

        &-title
            @extend %paragraph
            white-space: nowrap
            text-align: center

        &-button-wrap, &-title
            flex: 1

        &-button
            @extend %button

    &__calendar-wrap
        padding: 28px
        width: 100%
        .range-picker--mobile .range-picker__calendar.DayPicker
            overflow: unset

    &__buttons-wrap
        display: flex
        padding: 24px
        padding-top: 0
        justify-content: flex-end

        +mediamax(md)
            background-color: $background-default
            border: 2px solid rgba(245, 223, 184, 0.5)
            border-radius: 16px 16px 0px 0px
            padding: 16px 24px
            justify-content: space-between
            z-index: 2
            position: sticky
            bottom: 0

    &__buttons-wrap__hidden
        display: none

    &__button-wrap
        &:not(:last-child)
            margin-right: 12px

    &__button
        @extend %button

    &__button--primary
        @extend %button--primary

    &__button--second
        @extend %button--second
