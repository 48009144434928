@import "~@russpass/ui/build/assets/styles/common/vars.sass"
@import "~@russpass/ui/build/assets/styles/common/buttons.sass"
@import "~@russpass/ui/build/assets/styles/common/grid.sass"
@import "~@russpass/ui/build/assets/styles/common/typography.sass"

.download-button
    @extend %button
    display: flex
    align-items: center
    cursor: pointer
    margin-left: auto

    svg
        fill: $color-secondary

    &__text
        @extend %body
        margin-left: 5px
        color: $color-secondary

        span
            margin-left: 5px

        &--mobile-hidden
            +mediamax(md)
                display: none
