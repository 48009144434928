@import "~@russpass/ui/build/assets/styles/common/vars.sass"
@import "~@russpass/ui/build/assets/styles/common/buttons.sass"
@import "~@russpass/ui/build/assets/styles/common/grid.sass"
@import "~@russpass/ui/build/assets/styles/common/typography.sass"

.common-layout
    display: flex
    flex-direction: column
    padding-left: var(--sider-width)
    width: 100%
    height: 100%
    min-height: calc(var(--100vvh))
    overflow-y: auto

    +mediamax(md)
        padding-left: 0

    &__header
        display: flex
        justify-content: center
        align-items: center
        border-bottom: 2px solid $background-accent
        z-index: 2
        padding: 47px 56px

        +mediamax(md)
            border-bottom: none
            padding: 6px 11px
            position: relative

        &-title
            flex: 1
            text-align: center
            mix-blend-mode: screen
            white-space: nowrap
            @extend %headline

            +mediamax(md)
                font:
                    size: 20px

        &-button
            display: flex
            flex: 1

            &-burger
                @extend %button
                display: none

                +mediamax(md)
                    display: flex

            svg
                fill: $background-floating

    &__wrap
        flex: 1
        position: relative
        align-items: stretch
        display: flex

.common-layout__header--reverse-colors
    .common-layout__header-title
        filter: invert(1)

    .common-layout__header-button
        svg
            filter: invert(1)

.common-layout__header--hide-border
    border-bottom: none
