@import "~@russpass/ui/build/assets/styles/common/vars.sass"
@import "~@russpass/ui/build/assets/styles/common/buttons.sass"
@import "~@russpass/ui/build/assets/styles/common/grid.sass"
@import "~@russpass/ui/build/assets/styles/common/typography.sass"


.history-tabs
    width: 100%
    display: inline-flex
    overflow: hidden
    margin: 0 auto
    padding: 0 12px
    border-bottom: 2px solid $background-accent

    +mediamax(md)
        margin: 0 auto

    &__wrap
        display: inline-flex
        flex-wrap: nowrap
        margin: 0 auto
        overflow-x: auto

        -ms-overflow-style: none
        scrollbar-width: none

        &::-webkit-scrollbar
            display: none
