@import "~@russpass/ui/build/assets/styles/common/vars.sass"
@import "~@russpass/ui/build/assets/styles/common/buttons.sass"
@import "~@russpass/ui/build/assets/styles/common/grid.sass"
@import "~@russpass/ui/build/assets/styles/common/typography.sass"


.history-chart__tooltip
    @extend %caption
    background-color: $background-floating
    border-radius: $border-radius-md
    color: $color-text-secondary
    padding: 16px

    &-label
        margin-bottom: 8px

    &-data
        display: flex
        align-items: center
        color: $color-text-primary

    &-circle
        border-radius: 50%
        width: 8px
        height: 8px
        margin-right: 8px
