@import "~@russpass/ui/build/assets/styles/common/vars.sass"
@import "~@russpass/ui/build/assets/styles/common/buttons.sass"
@import "~@russpass/ui/build/assets/styles/common/grid.sass"
@import "~@russpass/ui/build/assets/styles/common/typography.sass"

.notification-error-wrap
    padding: 0px 12px

    position: absolute
    top: 24px
    left: 50%
    transform: translateX(-50%)
    z-index: 3

    max-width: 474px
    width: 100%

.notification-error
    width: 100%

    overflow: hidden
    background: $background-floating
    border-radius: $border-radius-md

    &__content
        padding: 12px 18px
        display: flex
        align-items: center
        justify-content: space-between
        margin: 0 auto

    &__info
        @extend %body
        margin-left: 20px
        width: 100%

    &__info-type
        display: -webkit-box
        -webkit-line-clamp: 2
        -webkit-box-orient: vertical
        word-break: break-word

    &__info-description
        opacity: .5
        white-space: nowrap
        text-overflow: ellipsis

    &__info-type,
    &__info-description
        max-width: 480px
        width: 100%
        overflow: hidden

    &__btn-wrap
        @extend %body

    &__btn
        @extend %button
        color: $color-secondary
