@import "~@russpass/ui/build/assets/styles/common/vars.sass"
@import "~@russpass/ui/build/assets/styles/common/buttons.sass"
@import "~@russpass/ui/build/assets/styles/common/grid.sass"
@import "~@russpass/ui/build/assets/styles/common/typography.sass"


.history-list
    display: flex
    flex-direction: column
    margin-top: 30px
    width: 100%
    padding: 0 14px
    @extend %body

    &__item
        display: flex
        width: 100%
        margin-bottom: 24px
        overflow: hidden

    &__item-icon
        margin-right: 14px
        width: 24px

    &__item-content
        display: flex
        flex-direction: column
        flex-grow: 1
        overflow: hidden

    &__item-common-info
        display: flex
        justify-content: space-between
        align-items: center
        margin-bottom: 2px

    &__item-ticket-date
        @extend %caption
        margin-left: 20px
        flex-shrink: 0

    &__item-ticket-num, &__item-ticket-date
        overflow: hidden
        text-overflow: ellipsis
        white-space: nowrap

    &__item-ticket-info
        color: $color-text-secondary
        margin-bottom: 2px
        overflow: hidden
        text-overflow: ellipsis
        white-space: nowrap

    &__item-ticket-status
        background-color: $background-accent
        color: $color-text-secondary
        border-radius: $border-radius-sm
        padding: 8px 12px
        margin-top: 8px
        overflow: hidden
        text-overflow: ellipsis
