@import "~@russpass/ui/build/assets/styles/common/vars.sass"
@import "~@russpass/ui/build/assets/styles/common/buttons.sass"
@import "~@russpass/ui/build/assets/styles/common/grid.sass"
@import "~@russpass/ui/build/assets/styles/common/typography.sass"


.aside-panel
    background: $background-default
    position: fixed
    top: 0
    bottom: 0
    left: 0
    z-index: 9
    display: flex
    flex-direction: column
    width: var(--sider-width)
    border-right: 2px solid $background-accent
    overflow-y: auto

    +mediamax(md)
        transition: all 0.3s ease
        left: calc((-1) * var(--sider-width))

    &__body
        display: flex
        flex-direction: column
        justify-content: space-between
        flex: 1
        padding: 20px 40px 40px 40px

        +mediamax(md)
            padding: 12px 24px 24px 24px

        &-close-button
            @extend %button
            display: none

            +mediamax(md)
                display: block

        &-header
            display: flex
            align-items: center
            justify-content: space-between
            flex-shrink: 0
            padding: 40px

            +mediamax(md)
                padding: 24px 12px 24px 24px

        &-logo
            display: flex
            height: 48px
            width: 48px

        &-nav
            display: flex
            flex-direction: column

            .active
                opacity: 1

            &-item
                display: flex
                align-items: center
                text-decoration: none
                opacity: .5
                @extend %body
                margin-bottom: 50px

                span
                    margin-left: 14px

        &-footer
            opacity: .5

        &-organization
            margin-bottom: 20px
            white-space: nowrap
            overflow: hidden
            text-overflow: ellipsis
            @extend %body

        &-signout

            &-button
                display: flex
                justify-content: space-between
                width: 100%

                span
                    margin-right: 20px
                    @extend %body

    &__support-item
        margin-left: 14px

        &-text
            @extend %caption
            opacity: .5
        &-link
            @extend %body
            color: $color-secondary
            text-decoration: none

.visible
    +mediamax(md)
        left: 0
        transition: all 0.3s ease
