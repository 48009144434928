@import "~@russpass/ui/build/assets/styles/common/vars.sass"
@import "~@russpass/ui/build/assets/styles/common/buttons.sass"
@import "~@russpass/ui/build/assets/styles/common/grid.sass"
@import "~@russpass/ui/build/assets/styles/common/typography.sass"


.signin
    display: flex
    flex-direction: column
    align-items: center
    height: 100%
    min-height: calc(var(--100vvh))
    justify-content: center
    overflow-y: auto
    position: relative

    &__logo
        width: 48px
        height: 48px

    &__header
        display: flex
        justify-content: center
        align-items: center
        width: 100%
        padding: 40px
        z-index: 1

        +mediamax(md)
            padding: 24px
            position: relative

        &-title
            flex: 1
            text-align: center
            mix-blend-mode: screen
            white-space: nowrap
            @extend %headline

            +mediamax(md)
                font:
                    size: 16px

        &-logo
            display: flex
            flex: 1

    &__form-wrap
        display: flex
        align-items: center
        width: 100%
        flex: 1
        max-width: 450px
        margin: auto
        padding-bottom: calc(var(--offset-h, 0) + 88px)

        +mediamax(md)
            padding-bottom: calc(var(--offset-h, 0) + 64px)
            align-items: flex-start

    &__form
        width: 100%
        margin: 80px 0

        +mediamax(md)
            margin: 40px 0

    &__btn-wrap
        padding: 16px 24px 16px 24px

    &__btn
        @extend %button
        @extend %button--primary

    &__footer
        text-align: center
        padding-bottom: 48px

        +mediamax(md)
            padding-bottom: 24px

        &-text
            @extend %caption
            color: $color-text-primary

        &-link

            text-decoration: none
            @extend %body

            color: $color-secondary
