@import "~@russpass/ui/build/assets/styles/common/vars.sass"
@import "~@russpass/ui/build/assets/styles/common/buttons.sass"
@import "~@russpass/ui/build/assets/styles/common/grid.sass"
@import "~@russpass/ui/build/assets/styles/common/typography.sass"


.table
    display: flex
    flex-direction: column
    margin-top: 50px
    @extend %body

    &__head
        color: $color-text-primary
        border-bottom: 1px solid $background-accent

        .table__row
            cursor: default
            &:hover
                background-color: transparent

    &__body
        color: $color-text-secondary
        width: 100%

    &__row
        display: flex
        cursor: pointer
        width: 100%

        &:hover
            background-color: $background-accent

        &:not(:last-child)
            border-bottom: 1px solid $background-accent

    &__ceil
        flex: 1
        padding: 8px 16px
        overflow: hidden
        text-overflow: ellipsis

        &:not(:last-child)
            border-right: 1px solid $background-accent

    &__ceil--used-color
        color: $color-hint-success

    &__ceil--unused-color
        color: $color-hint-error

    &__ceil--text-nowrap
        white-space: nowrap
