@import "~@russpass/ui/build/assets/styles/common/vars.sass"
@import "~@russpass/ui/build/assets/styles/common/buttons.sass"
@import "~@russpass/ui/build/assets/styles/common/grid.sass"
@import "~@russpass/ui/build/assets/styles/common/typography.sass"


.ticket-status-success
    position: absolute
    max-width: 450px
    top: 24px
    padding: 12px 18px
    display: flex
    align-items: center
    justify-content: space-between
    margin: 0 auto
    left: 50%
    transform: translateX(-50%)
    background: $background-floating
    border-radius: $border-radius-md
    z-index: 3
    overflow: hidden

    +mediamax(md)
        padding: 12px 16px
        left: 12px
        right: 12px
        transform: translateX(0)
        top: 0

    &__info
        @extend %body
        margin-left: 20px
        width: 100%
        overflow: hidden

        +mediamax(md)
            margin-left: 12px

        &-description
            display: flex
            flex-wrap: nowrap

            &-time
                white-space: nowrap
                margin-right: 8px

            &-product-name
                opacity: .5
                width: 100%
                white-space: nowrap
                overflow: hidden
                text-overflow: ellipsis

        &-type
            width: 100%
            white-space: nowrap
            overflow: hidden
            text-overflow: ellipsis

    &__btn-wrap
        @extend %body

    &__btn
        @extend %button
        color: $color-secondary
