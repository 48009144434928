@import "~@russpass/ui/build/assets/styles/common/vars.sass"
@import "~@russpass/ui/build/assets/styles/common/buttons.sass"
@import "~@russpass/ui/build/assets/styles/common/grid.sass"
@import "~@russpass/ui/build/assets/styles/common/typography.sass"

// Normalize
@import "~normalize.css/normalize.css"

@import "~@russpass/ui"

@import "lib/react-toastify"

\:root
    --sider-width: 310px

html
    scroll-behavior: smooth
    @extend %caption

    &.fixed
        overflow: hidden

        +mediamax(md)
            overflow: auto
