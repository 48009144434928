@import '~react-toastify/dist/ReactToastify.min.css'

.Toastify
    &__toast-container
        width: auto
        padding: 0
        max-width: 460px
        right: 16px

        +mediamax(md)
            right: 12px
            left: 12px

    &__close-button
        display: none

.Toastify__toast-container--top-center
    top: 48px

.notification
    min-width: 196px
    margin-bottom: 8px
    padding: 14px 16px
    border-radius: $border-radius-sm
    min-height: 40px
    box-shadow: 0px 12px 16px rgba(0, 0, 0, 0.04)

    &--error
        color: $color-hint-error
        background-color: $background-floating

    &--success
        color: #fff

    &__body
        padding: 0
        margin: 0
        line-height: 22px
        font:
            family: $font-family-suisse-regular
            size: 16px
